import { ForwardRefRenderFunction, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

interface SelectOption {
  value: string | number
  label: string | number
}

interface SelectProps
  extends Omit<React.HTMLProps<HTMLSelectElement>, 'label'> {
  name: string
  label?: string
  error?: FieldError
  options: SelectOption[]
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { name, label, error = undefined, options, ...rest },
  ref,
) => {
  return (
    <div className="w-full flex flex-col">
      {label && (
        <label htmlFor={name} className="block font-bold mb-2">
          {label}
        </label>
      )}
      <select
        id={name}
        name={name}
        {...rest}
        ref={ref}
        className={`w-full py-3 px-2 border-2 rounded-xl outline-none ${
          error ? 'border-red-500' : 'border-black'
        } hover:border-yellow-600 focus:border-yellow-600`}
      >
        <option value="">Selecione um Profissional</option>

        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 mt-2">{error.message}</p>}
    </div>
  )
}

export const Select = forwardRef(SelectBase)
