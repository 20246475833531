import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import ptBr from 'dayjs/locale/pt-br'

import { api } from '../lib/axios'

import { WhatsAppButton } from '../components/WhatsAppButton'

import bannerImage from '../assets/banner.jpg'
import { TailSpin } from 'react-loader-spinner'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.locale(ptBr)

interface ResponseScheduling {
  id: string
  contact_id: string
  subject: string
  created_at: Date
}

export function Success() {
  const { schedulingId } = useParams()

  const [scheduling, setScheduling] = useState<ResponseScheduling>()

  useEffect(() => {
    document.title = 'Agendamento realizado com sucesso | Óticas Life is...'
  }, [])

  useEffect(() => {
    api
      .get<ResponseScheduling>(`/contacts/scheduling/${schedulingId}`)
      .then((response) => {
        const { data } = response

        setScheduling(data)
      })
  }, [schedulingId])

  if (!scheduling) {
    return (
      <main className="w-full h-screen flex justify-center items-center">
        <TailSpin
          height="90"
          width="90"
          color="#f9ae00"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </main>
    )
  }

  return (
    <main>
      <section className="w-full">
        <img className="w-full" src={bannerImage} alt="" />
      </section>

      <WhatsAppButton number="5511990040313" message="Ola" />

      <section className="w-full flex flex-col justify-center items-center my-20">
        <h2 className="text-5xl font-bold text-center">
          Agendamento realizado com sucesso
        </h2>

        <p className="text-xl text-center w-full max-w-lg my-10">
          Seus dados foram enviados e seu agendamento foi registrado em nosso
          sistema.
        </p>

        {scheduling && (
          <h3 className="text-4xl font-bold text-center">
            {dayjs(scheduling.created_at).format(
              'dddd, D [de] MMMM [de] YYYY, [às] HH:mm',
            )}
          </h3>
        )}
      </section>
    </main>
  )
}
