import { FacebookLogo, InstagramLogo } from 'phosphor-react'
import { SocialButton } from './SocialButton'

export function Footer() {
  return (
    <footer className="w-full flex items-center justify-center bg-black">
      <div className="w-full max-w-7xl flex items-center justify-between gap-8 px-8 py-6 max-sm:flex-col">
        <p className="text-white">
          2023© OTICAS LIFE IS BRASIL todos os direitos reservados. <br />
          CNPJ: 50.867.516/0001-06 | Razão social: ALAJE PRODUTOS OTICOS LTDA{' '}
          <br />
          Endereço: Rua Conselheiro Saraiva, 229 CONJ 1005 - Santana - São Paulo
          - SP <br />
        </p>
        <div className="flex items-center justify-center gap-3">
          <SocialButton
            href="#"
            icon={<FacebookLogo weight="fill" size={30} />}
          />
          <SocialButton
            href="#"
            icon={<InstagramLogo weight="fill" size={30} />}
          />
        </div>
      </div>
    </footer>
  )
}
