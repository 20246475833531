import { ReactNode } from 'react'

interface SocialButtonProps {
  icon: ReactNode
  href: string
}

export function SocialButton({ href, icon }: SocialButtonProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="w-[45px] h-[45px] flex items-center justify-center bg-yellow-600 text-black rounded-lg hover:brightness-90"
    >
      {icon}
    </a>
  )
}
