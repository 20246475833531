import { WhatsappLogo } from 'phosphor-react'

interface WhatsAppButtonProps {
  number: string
  message?: string
}

export function WhatsAppButton({ number, message }: WhatsAppButtonProps) {
  return (
    <a
      href={`https://wa.me/${number}/${message && `?text=${message}`}`}
      target="_blank"
      rel="noopener noreferrer"
      className="w-[50px] h-[50px] flex items-center justify-center bg-[#34af23] text-white rounded-full fixed bottom-4 right-4 hover:brightness-90"
    >
      <WhatsappLogo size={30} />
    </a>
  )
}
