import { useEffect } from 'react'

import { ImageError404 } from '../components/ImageError404'

const Error404 = () => {
  useEffect(() => {
    document.title = 'Página não encontrada | Óticas Life is...'
  }, [])

  return (
    <main className="flex justify-center items-center w-full h-screen">
      <div className="flex w-full max-w-[1250px] items-center justify-center gap-8">
        <div className="flex flex-col w-full max-w-[500px] p-4">
          <h2 className="text-4xl font-bold text-blue-800">
            Ops, esta página não foi encontrada
          </h2>
          <p className="text-lg mt-4">
            Parece que você se perdeu... Tente voltar para a página anterior ou
            acessar a home.
          </p>
        </div>
        <div className="flex w-full max-w-[500px]">
          <ImageError404 />
        </div>
      </div>
    </main>
  )
}

export default Error404
