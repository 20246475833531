import { ForwardRefRenderFunction, ReactNode, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

interface InputPros extends Omit<React.HTMLProps<HTMLInputElement>, 'label'> {
  name: string
  label?: string
  error?: FieldError
  iconLeft?: ReactNode
  iconRight?: ReactNode
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputPros> = (
  { name, label, error = undefined, iconLeft, iconRight, ...rest },
  ref,
) => {
  return (
    <div className="w-full flex flex-col">
      {label && (
        <label htmlFor={name} className="block font-bold mb-2">
          {label}
        </label>
      )}
      <div className="w-full relative flex items-center">
        {iconLeft && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            {iconLeft}
          </span>
        )}
        <input
          id={name}
          name={name}
          {...rest}
          ref={ref}
          className={`w-full py-3 ${iconLeft && 'pl-9 pr-3'} ${
            iconRight && 'pl-3 pr-9'
          } border-2 rounded-xl outline-none ${
            error ? 'border-red-500' : 'border-black'
          } hover:border-yellow-600 focus:border-yellow-600`}
        />
        {iconRight && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
            {iconRight}
          </span>
        )}
      </div>
      {error && <p className="text-red-500 mt-2">{error.message}</p>}
    </div>
  )
}

export const Input = forwardRef(InputBase)
