import logoImage from '../assets/logo_lifeis.png'
import mascotImage from '../assets/mascot2.png'

export function Header() {
  return (
    <header className="w-full flex items-center justify-center bg-black">
      <div className="w-full max-w-7xl flex items-center justify-between gap-4 h-28 p-2">
        <img
          src={logoImage}
          alt="Óticas Life is"
          className="w-full max-w-[200px]"
        />
        <img
          src={mascotImage}
          alt="Mascote da Óticas Life is"
          className="w-full max-w-[60px]"
        />
      </div>
    </header>
  )
}
