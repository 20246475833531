import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  User,
  EnvelopeSimple,
  WhatsappLogo,
  Calendar,
  CalendarPlus,
} from 'phosphor-react'
import { ThreeDots } from 'react-loader-spinner'
import ReactInputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'

import { api } from '../lib/axios'

import { RadioInput } from '../components/Form/RadioInput'
import { Input } from '../components/Form/Input'
import { Avatar } from '../components/Avatar'
import { Select } from '../components/Form/Select'
import { WhatsAppButton } from '../components/WhatsAppButton'

import bannerImage from '../assets/banner.jpg'

const registerExamFormSchema = z.object({
  name: z
    .string()
    .min(10, { message: 'O campo nome completo é obrigatório.' })
    .toUpperCase(),
  cellPhone: z.string().min(14, { message: 'O campo celular é obrigatório.' }),
  email: z
    .string()
    .email({ message: 'O campo e-mail é obrigatório.' })
    .toLowerCase(),
  professional: z
    .string()
    .min(1, { message: 'Por favor, selecione um profissional para o exame.' }),
  date: z
    .string()
    .min(10, { message: 'Por favor, selecione uma data para o exame.' }),
  hour: z
    .string()
    .min(1, { message: 'Por favor, selecione um horário para o exame.' }),
})

type RegisterExamFormSchema = z.infer<typeof registerExamFormSchema>

interface Professional {
  contactId: string
  name: string
  avatarUrl: string
  bio: string
}

interface ResponseProfessional {
  professionals: Professional[]
}

interface ResponseExam {
  schedulingId: string
}

type ResponseHoursFromDay = Array<{
  hour: string
  available: boolean
}>

interface OptionProfessional {
  value: string
  label: string
}

interface OptionHour {
  value: string
  label: string
}

export function Home() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<RegisterExamFormSchema>({
    resolver: zodResolver(registerExamFormSchema),
  })

  const [professional, setProfessional] = useState<Professional>()
  const [professionals, setProfessionals] = useState<Professional[]>([])
  const [optionsProfessionals, setOptionsProfessionals] = useState<
    OptionProfessional[]
  >([])
  const [dateSelector, setDateSelector] = useState<string>()
  const [optionsHours, setOptionsHours] = useState<OptionHour[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    api
      .get<ResponseProfessional>('/contacts/professionals')
      .then((response) => {
        const professionalsReponse = response.data.professionals

        setProfessionals(professionalsReponse)

        const options = professionalsReponse.map((professionalOption) => {
          return {
            value: professionalOption.contactId,
            label: professionalOption.name,
          }
        })

        setOptionsProfessionals(options)
      })
  }, [])

  async function handleExam(data: RegisterExamFormSchema) {
    try {
      const response = await api.post<ResponseExam>('/contacts', {
        name: data.name,
        email: data.email,
        cellPhone: data.cellPhone.replace(/\D/g, ''),
        professionalId: data.professional,
        dateExam: data.date,
        hourExam: data.hour,
      })

      navigate(`/success/${response.data.schedulingId}`)
    } catch (err) {
      console.log(err)
    }
  }

  function handleSetInfoProfessional(contactId: string) {
    const professionalInfo = professionals.find(
      (professionalFind) => professionalFind.contactId === contactId,
    )

    setProfessional(professionalInfo)
  }

  async function handleSetOptionsHoursProfessional(
    dateExam: string,
    contactId: string,
  ) {
    try {
      const response = await api.post<ResponseHoursFromDay>(
        '/contacts/scheduling/availability/hours',
        {
          professionalId: contactId,
          dateExam,
        },
      )

      const hoursReponse = response.data

      const options = hoursReponse
        .filter((hourOption) => hourOption.available) // Filtra os disponíveis
        .map((hourOption) => ({
          value: hourOption.hour,
          label: hourOption.hour,
        }))

      setDateSelector(dateExam)
      setOptionsHours(options)
    } catch (err) {
      console.log(err)
    }
  }

  const currentDate = new Date()

  const formattedCurrentDate = currentDate.toISOString().slice(0, 10)

  return (
    <main>
      <section className="w-full">
        <img className="w-full" src={bannerImage} alt="" />
      </section>

      <WhatsAppButton number="5511990040313" message="Ola" />

      <section className="w-full flex justify-center items-center my-8">
        <form
          onSubmit={handleSubmit(handleExam)}
          className="w-full max-w-3xl flex justify-center items-center flex-col gap-6 border-2 border-yellow-600 rounded-xl p-8 mx-4 shadow-2xl"
        >
          <h2 className="text-4xl font-bold text-center">
            Agendamento de exame
          </h2>

          <Input
            label="Nome Completo:"
            placeholder="Digite seu nome completo"
            iconLeft={<User weight="fill" />}
            {...register('name')}
            error={errors.name}
          />

          <Input
            type="email"
            label="E-mail:"
            placeholder="Digite seu e-mail"
            iconLeft={<EnvelopeSimple weight="fill" />}
            {...register('email')}
            error={errors.email}
          />

          <div className="w-full flex flex-col">
            <label htmlFor="cellPhone" className="block font-bold mb-2">
              Celular:
            </label>
            <div className="w-full relative flex items-center">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <WhatsappLogo weight="fill" />
              </span>
              <ReactInputMask
                id="cellPhone"
                mask="(99)99999-9999"
                placeholder="Digite seu celular"
                className={`w-full py-3 pl-9 pr-3 border-2 rounded-xl outline-none ${
                  errors.cellPhone?.message ? 'border-red-500' : 'border-black'
                } hover:border-yellow-600 focus:border-yellow-600`}
                {...register('cellPhone')}
              />
            </div>
            {errors.cellPhone?.message && (
              <p className="text-red-500 mt-2">{errors.cellPhone.message}</p>
            )}
          </div>

          <Select
            label="Profissional:"
            options={optionsProfessionals}
            {...register('professional')}
            error={errors.professional}
            onChange={(e) => handleSetInfoProfessional(e.currentTarget.value)}
          />

          {professional && (
            <div className="flex gap-6 items-center max-sm:flex-col">
              <Avatar url={professional.avatarUrl} name={professional.name} />
              <p className="flex-1">{professional.bio}</p>
            </div>
          )}

          {professional && (
            <Input
              type="date"
              label="Data do exame:"
              placeholder="Selecione uma data"
              min={formattedCurrentDate}
              iconLeft={<Calendar weight="fill" />}
              {...register('date')}
              error={errors.date}
              onChange={(e) =>
                handleSetOptionsHoursProfessional(
                  e.currentTarget.value,
                  professional.contactId,
                )
              }
            />
          )}

          {optionsHours.length > 0 ? (
            <RadioInput
              options={optionsHours}
              label="Selecione um Horário:"
              {...register('hour')}
              error={errors.hour}
            />
          ) : (
            dateSelector && (
              <p>
                Peço desculpas, mas não tenho disponibilidade de horários neste
                momento. Por gentileza, selecione outra data. Agradeço pela
                compreensão!
              </p>
            )
          )}

          <button
            type="submit"
            className="flex justify-center items-center bg-yellow-600 font-bold text-2xl w-52 h-14 rounded-xl disabled:brightness-90 transition-all hover:brightness-90"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <ThreeDots width="40px" radius={9} color="#000000" />
            ) : (
              <div className="flex justify-center items-center gap-2">
                <CalendarPlus weight="fill" />
                Agendar
              </div>
            )}
          </button>
        </form>
      </section>
    </main>
  )
}
