import { Route, Routes as RoutesDOM } from 'react-router-dom'

import { Home } from '../pages/Home'
import { Success } from '../pages/Success'
import Error404 from '../pages/Error404'

export const Routes = () => {
  return (
    <RoutesDOM>
      <Route path="*" element={<Error404 />} />
      <Route path="/" element={<Home />} />
      <Route path="/success/:schedulingId" element={<Success />} />
    </RoutesDOM>
  )
}
