import * as AvatarRadixUi from '@radix-ui/react-avatar'

interface AvatarProps {
  name: string
  url?: string
}

export function Avatar({ name, url }: AvatarProps) {
  return (
    <AvatarRadixUi.Root className="bg-blackA3 inline-flex h-[90px] w-[90px] select-none items-center justify-center overflow-hidden rounded-full align-middle">
      <AvatarRadixUi.Image
        className="h-full w-full rounded-[inherit] object-cover"
        src={url}
        alt={name}
      />
      <AvatarRadixUi.Fallback
        className="text-violet11 leading-1 flex h-full w-full items-center justify-center bg-yellow-600 text-[15px] font-medium text-center"
        delayMs={600}
      >
        {name}
      </AvatarRadixUi.Fallback>
    </AvatarRadixUi.Root>
  )
}
