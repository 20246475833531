import { ForwardRefRenderFunction, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

interface RadioOption {
  value: string
  label: string
}

interface RadioInputProps {
  name: string
  label?: string
  error?: FieldError
  options: RadioOption[]
}

const RadioInputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  RadioInputProps
> = ({ name, label, error = undefined, options, ...rest }, ref) => {
  return (
    <div className="w-full">
      <div className="w-full max-w-xs flex flex-col justify-center gap-3">
        {label && (
          <label htmlFor={name} className="block font-bold">
            {label}
          </label>
        )}

        <div className="w-full flex flex-wrap items-center gap-1">
          {options.map((option) => (
            <div key={option.value}>
              <input
                defaultChecked={options[0].value === option.value}
                {...rest}
                ref={ref}
                type="radio"
                name={name}
                id={`${name}-${option.label}`}
                value={option.value}
                className="hidden peer"
              />
              <label
                htmlFor={`${name}-${option.label}`}
                className="flex items-center justify-center w-[60px] h-[40px] rounded-lg cursor-pointer font-bold text-yellow-600 bg-black border-2 border-transparent transition-all peer-checked:text-black peer-checked:bg-yellow-600 peer-checked:border-black hover:bg-yellow-600 hover:text-black"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>

        {error && <p className="text-red-500 mt-2">{error.message}</p>}
      </div>
    </div>
  )
}

export const RadioInput = forwardRef(RadioInputBase)
